import React, { Component } from "react"
import { graphql } from 'gatsby'
import ProductGrid from '../components/productGrid'
import AdventureBox from '../components/productGrid/Adventure/index'
import AppBar from '../components/appBar'
import Typography from '@material-ui/core/Typography';


class MultiBox extends Component {

    render() {

        return (
            <React.Fragment>
                <AppBar />
                <div>
                    <Typography variant="h4" component="h3" gutterBottom>
                        Multi box text page
          </Typography>
                    <Typography variant="h4" component="h3" gutterBottom>
                        Multi box text page
          </Typography>
                    <Typography variant="h4" component="h3" gutterBottom>
                        these are tour boxes
          </Typography>
                </div>

                <ProductGrid />



                <Typography variant="h4" component="h3" gutterBottom>
                    these are adventure boxes
          </Typography>
                <AdventureBox />
            </React.Fragment>
        )

    }
}


export default MultiBox
